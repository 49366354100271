var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"featured-posts"},[_c('div',{staticClass:"mx-2"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Blog.featured')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.featuredArticle.length > 0),expression:"featuredArticle.length > 0"}],staticClass:"row"},_vm._l((_vm.featuredArticle),function(dataFeatured,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('div',{staticClass:"item"},[_c('router-link',{attrs:{"to":{
                                name: 'blogdetail',
                                params: {slug: dataFeatured.slug},
                            }}},[_c('img',{attrs:{"src":dataFeatured.image,"alt":""}})]),_c('div',{staticClass:"text"},[_c('router-link',{attrs:{"to":{
                                    name: 'blogdetail',
                                    params: {slug: dataFeatured.slug},
                                }}},[_c('h4',{domProps:{"innerHTML":_vm._s(dataFeatured[_vm.titleLang])}})]),_c('p',{domProps:{"innerHTML":_vm._s(
                                    _vm.getSubstring(dataFeatured[_vm.textLang])
                                )}}),_c('router-link',{staticClass:"button",attrs:{"to":{
                                    name: 'blogdetail',
                                    params: {slug: dataFeatured.slug},
                                }}},[_vm._v(" "+_vm._s(_vm.$t('Blog.readMore'))+" "),_c('img',{attrs:{"src":require("@/assets/mainImages/icons/arrow-right-light.svg")}})])],1)],1)])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.featuredArticle.length == 0),expression:"featuredArticle.length == 0"}],staticClass:"row"},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('div',{staticClass:"item"},[_c('b-skeleton-img'),_c('div',{staticClass:"text mt-2"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"25%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"50%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"20%"}})],1)],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }