<template>
    <div>
        <div v-if="article">
            <!-- Blog Detail Banner -->
            <section class="blog-detail-banner">
                <div class="mx-2">
                    <div class="container">
                        <ul class="breadcrumb">
                            <li>
                                <router-link :to="{name: 'blog'}">
                                    {{ $t('NavbarFirst.blog') }}
                                </router-link>
                                <i class="fa fa-caret-right"></i>
                            </li>
                            <li>{{ article[categoryLang][categoryLang] }}</li>
                        </ul>

                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <img :src="article.image" alt="" class="main" />
                            </div>

                            <div class="col-lg-6">
                                <div class="text">
                                    <h6>
                                        {{ getDateArticle(article.published_at) }}
                                    </h6>
                                    <h4 v-html="article[titleLang]"></h4>
                                    <!-- <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Nulla nulla ipsum,
                                        gravida pellentesque massa nec, feugiat
                                        sagittis erat.
                                    </p> -->

                                    <div class="user">
                                        <span>
                                            {{
                                                article[categoryLang][
                                                    categoryLang
                                                ]
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Blog Detail Banner End -->

            <!-- Blog Content -->
            <section class="blog-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="text">
                                <span
                                    v-show="showArticle"
                                    class="spanArticle"
                                    v-html="article[textLang]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Blog Content End -->
        </div>

        <div v-else>
            <!-- Blog Detail Banner -->
            <section class="blog-detail-banner">
                <div class="mx-2">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <b-skeleton-img></b-skeleton-img>
                            </div>

                            <div class="col-lg-6">
                                <div class="text">
                                    <b-skeleton
                                        animation="wave"
                                        width="25%"
                                    ></b-skeleton>
                                    <b-skeleton
                                        animation="wave"
                                        width="85%"
                                    ></b-skeleton>
                                    <b-skeleton
                                        animation="wave"
                                        width="100%"
                                    ></b-skeleton>

                                    <div class="user">
                                        <b-skeleton
                                            animation="wave"
                                            width="20%"
                                        ></b-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Blog Detail Banner End -->

            <!-- Blog Content -->
            <section class="blog-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="text">
                                <b-skeleton
                                    animation="wave"
                                    width="85%"
                                ></b-skeleton>
                                <b-skeleton
                                    animation="wave"
                                    width="55%"
                                ></b-skeleton>
                                <b-skeleton
                                    animation="wave"
                                    width="70%"
                                ></b-skeleton>

                                <b-skeleton
                                    class="mt-2"
                                    animation="wave"
                                    width="85%"
                                ></b-skeleton>
                                <b-skeleton
                                    animation="wave"
                                    width="55%"
                                ></b-skeleton>
                                <b-skeleton
                                    animation="wave"
                                    width="70%"
                                ></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Blog Content End -->
        </div>
    </div>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
import useAppConfig from '@core/app-config/useAppConfig';
import axiosIns from '@/libs/axios';
import FeatureArticle from '@/@core/components/Blog/FeatureArticle.vue';

export default {
    mixins: [dateMixins],
    components: {FeatureArticle},
    data() {
        return {
            article: '',
            config: useAppConfig(),
            featuredArticle: [],
            showArticle: false,
        };
    },
    computed: {
        titleLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_title' : 'eng_title';
            } else return this.getLang == 'sv' ? 'swe_title' : 'eng_title';
        },
        textLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_text' : 'eng_text';
            } else return this.getLang == 'sv' ? 'swe_text' : 'eng_text';
        },
        categoryLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_category' : 'eng_category';
            } else
                return this.getLang == 'sv' ? 'swe_category' : 'eng_category';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        getArticle(slug) {
            return new Promise(resolve => {
                axiosIns
                    .get(`/blogs/${slug}`, {
                        transformRequest: (data, headers) => {
                            delete headers.Authorization;
                            return data;
                        },
                    })
                    .then(res => {
                        const {data} = res;

                        this.article = data;

                        resolve(true);
                    });
            });
        },

        renderImage() {
            let getSpan = document.querySelectorAll('.spanArticle p');

            getSpan.forEach(element => {
                let getImg = element.getElementsByTagName('img');

                if (getImg.length > 0) {
                    getImg.forEach(img => {
                        let imgSrc = img.src;

                        let baseUrl = window.location.origin;

                        let imgLocationResult = imgSrc.split(baseUrl)[1];

                        img.setAttribute(
                            'src',
                            `${process.env.VUE_APP_SERVICE_URL}${imgLocationResult}`
                        );
                    });
                }
                this.showArticle = true;
            });
        },

        getFeatureArtice() {
            axiosIns
                .get('/blogs', {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {results} = res.data;

                    let featuredArticle = [];

                    results.forEach(element => {
                        if (element.featured == true)
                            featuredArticle.push(element);
                    });

                    this.featuredArticle = featuredArticle;

                    this.renderImage();
                });
        },
    },
    mounted() {
        this.getArticle(this.$route.params.slug).then(res => {
            this.renderImage();
        });
        this.locale = localStorage.getItem('mainLang');
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/blogdetail.scss';
</style>
