<template>
    <section class="featured-posts">
        <div class="mx-2">
            <div class="container">
                <h2 class="title">{{ $t('Blog.featured') }}</h2>

                <div class="row" v-show="featuredArticle.length > 0">
                    <div
                        v-for="(dataFeatured, index) in featuredArticle"
                        :key="index"
                        class="col-lg-4"
                    >
                        <div class="item">
                            <router-link
                                :to="{
                                    name: 'blogdetail',
                                    params: {slug: dataFeatured.slug},
                                }"
                            >
                                <img :src="dataFeatured.image" alt="" />
                            </router-link>

                            <div class="text">
                                <router-link
                                    :to="{
                                        name: 'blogdetail',
                                        params: {slug: dataFeatured.slug},
                                    }"
                                >
                                    <h4 v-html="dataFeatured[titleLang]" />
                                </router-link>
                                <p
                                    v-html="
                                        getSubstring(dataFeatured[textLang])
                                    "
                                />

                                <router-link
                                    :to="{
                                        name: 'blogdetail',
                                        params: {slug: dataFeatured.slug},
                                    }"
                                    class="button"
                                >
                                    {{ $t('Blog.readMore') }}
                                    <img
                                        src="@/assets/mainImages/icons/arrow-right-light.svg"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-show="featuredArticle.length == 0">
                    <div v-for="index in 3" :key="index" class="col-lg-4">
                        <div class="item">
                            <b-skeleton-img></b-skeleton-img>

                            <div class="text mt-2">
                                <b-skeleton
                                    animation="wave"
                                    width="25%"
                                ></b-skeleton>
                                <b-skeleton
                                    animation="wave"
                                    width="50%"
                                ></b-skeleton>

                                <b-skeleton
                                    animation="wave"
                                    width="20%"
                                ></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
export default {
    mixins: [dateMixins],
    props: {
        featuredArticle: Array,
        titleLang: String,
        textLang: String,
    },

    methods: {
        getSubstring(value) {
            return value.substring(0, 31);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/blog.scss';
</style>
